<template>
	<div class="home pa-6">
		<h1>Settings will be here</h1>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {

			}
		},
		methods:
		{

		}
	}
</script>
