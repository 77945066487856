<template>
	<div class="home pa-md-6">
		<v-list subheader two-line flat>
			<v-subheader>Demo List</v-subheader>
			  <div v-for="task in tasks" :key="task.id">
				<v-list-item 
				@click="toggleTaskStatus(task.id)"
				>
					<template v-slot:default>
						<v-list-item-action >
							<v-checkbox :input-value="task.done" color="primary" >

							</v-checkbox>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title :class="{ 'text-decoration-line-through' : task.done }">{{ task.title }}</v-list-item-title>
							<v-list-item-subtitle>{{ task.subtitle }}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-list-item>
			</div>
		</v-list>
	</div>
</template>

<script>


	export default {
		name: 'Home',
		data() {
			return {
				tasks: [
				{
					id: 1,
					title: 'Fridge',
					subtitle: 'Closed and locked and open containers secured',
					done: false
				},
				{
					id: 2,
					title: 'Cupboards',
					subtitle: 'closed and locked',
					done: false
				},
				{
					id: 3,
					title: 'Gas',
					subtitle: 'turned off unless you have secumotion regulator',
					done: false
				}
				]
			}
		},
		methods:
		{
			toggleTaskStatus(id)
			{
				let task = this.tasks.filter(task => task.id === id)[0];
				task.done = !task.done;
			}
		}
	}
</script>
