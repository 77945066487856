
<template>
  <v-app id="inspire">

    <!-- Left Navigation Drawer-->
    <v-navigation-drawer v-model="drawer" app>
<!--      <v-img src="img/logo.png" max-width="70"/>-->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Motorhome Fun
          </v-list-item-title>
          <v-list-item-subtitle>
            Lists
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <!-- Main top bar -->
    <v-app-bar app color="primary" dark src="img/sky.jpg" prominent>
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)" ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>MotorhomeFun List Manager</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- App main area-->
    <v-main>
      <!--  -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ 
      drawer: null,
      items: [
      { title: 'Lists', icon: 'mdi-format-list-checks', to: '/' },
      { title: 'Settings', icon: 'mdi-cog-outline', to: '/settings' },
      { title: 'About', icon: 'mdi-help-box', to: '/about' },
      ],
      right: null,
    }),
  }
</script>

<style scoped>

</style>
